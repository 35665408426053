import Vue from 'vue';
import App from './路由/App.vue';
import 'animate.css';
import axios from "axios";
Vue.prototype.$axios = axios
Vue.config.productionTip = false
// 全局过滤器 
Vue.filter('mynum', (text) => {
  return text + 3
})
import mearddd from "../src/01_组件通信/son/son1.vue";
Vue.component('mearddd', mearddd)//全局组件
window.$bus = new Vue()
// 把router 注入到vue里面去
import vuerouter from 'vue-router'
Vue.use(vuerouter)
// 注册router 
import router from './路由/router'
// 是指路由重复。
const originalPush = vuerouter.prototype.push;
// 修改 原型对象中的push方法
vuerouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

