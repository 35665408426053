import VueRouter from "vue-router";
// VueRouter 路由器
import home from "./home.vue"
import shop from './shop.vue'
const routers = new VueRouter({
    // 区别history路径不带#，比较美观
    // history模式，刷新后会把路径当成接口资源，但是hash模式不会
    // hash路径带#，不美观
    mode: 'history',
    // router 路由
    routes: [
        {
            // path 为' / '代表一进入页面就展示的页面
            path: '/',
            name: 'app',
            component: () => import('./App.vue'),
            // 重定向
            redirect: { name: 'login' }
        },
        // 主页面
        {
            path: '/home',
            component: home,
            name: 'home',
            redirect: {
                name: 'user'
            },
            children: [{
                //路由跳转的路径，自己命名 
                path: '/shop',
                // 跳转到的组件（有两种写法）
                /* 1 */  component: shop,
                name: 'shop',
            },
            {
                path: '/user',
                /* 2 */  component: () => import('./user.vue'),
                name: 'user',
                meta: {
                    title: 'login',
                    isAutr: true
                }
            }]

        },
        // {
        //     //路由跳转的路径，自己命名 
        //     path: '/home',
        // // 跳转到的组件（有两种写法）
        // /* 1 */  component: home,
        //     name: 'home'
        // },

        // login页面
        {
            path: '/login',
        /* 2 */  component: () => import('./login.vue'),
            name: 'login',
            meta: {
                title: 'login',
                isAutr: true
            }
        }
    ]
})
// 路由守卫
// 第一个 前置守卫
routers.beforeEach((to, from, next) => {
    console.log('to', to, 'from', from);
    const userN = JSON.parse(localStorage.getItem('user'))
    const loggedIn = localStorage.getItem("loggedIn");
    // console.log(userN);

    // 放行
    // next()
    // 1.
    // if (!userN && to.path != '/login') {
    //     console.log(1, to, from);
    //     next({ name: 'login' })
    // } else {
    //     console.log(3, to.path);
    //     if (loggedIn && to.path != '/login') {
    //         console.log(2);
    //         next('/home')
    //     } else {
    //         next()
    //     }
    // }
    // next()

    // 2.
    // 本地有值
    if (loggedIn) {
        // 如果是前往login页面
        if (to.name == 'login') {
            // 那么就直接给他一个固定地址(主页面)
            next({ name: 'home' })
        }
        // 如果不是前往login页面 
        else {
            // 直接放行
            next()
        }
    }
    // 本地没有值
    else {
        // 去login以外的页面
        if (to.name != 'login') {
            // 那么就直接让他恢复到login页面
            next({ name: 'login' })
        }
        // 在login页面就继续放行
        else {
            next()
        }
    }

    // 禁止通行
    // next(false)
    // 指定路由
    // next({ name: '', path: '' })

    // login页面使用前置守卫控制用户不允许直接进入home页面

})


export default routers