<template>
    <div>
        <button @click="fk">后退</button>
        &nbsp;
        &nbsp;
        &nbsp;
        <button @click="bc">前进</button>
        <hr>
        <!-- 编程式导航 -->
        <button @click="ck1('user')">用户管理</button> &nbsp;<button @click="ck1('shop')">商品管理</button>
        <router-view></router-view><br>
        <br>
        <button @click="breaK" class="aaa">退出登录</button>
    </div>
</template>

<script>
export default {
    name: 'ProjectHome',

    data() {
        return {

        };
    },

    mounted() {

    },
    created() {
        // 判断用户是否登录
        const loggedIn = localStorage.getItem('loggedIn');
        if (!loggedIn) {
            // 如果未登录，则强制跳转到登录页面
            this.$router.push({ name: 'login' });
        }else{
            this.$router.push({ name: 'home' });

        }

    },

    methods: {
        ck1(el) {
            // 传参query可以显示在地址栏，params传参不会显示在地址栏
            // this.$router.push(`/${el}`)
            // this.$router.push({ path: `/${el}` })
            // this.$router.push({ name: el })

            // 第一 push(有历史记录)
            // this.$router.push({ name: el, query: { id: 'home' } })
            this.$router.push({ name: el })

            // path跟params组合无法进行params传参
            // this.$router.push({ path: `/${el}`, params: { id: 'params无法传参' } })

            // 第二 replace(没有历史记录)
            // this.$router.replace({ name: el, query: { id: 1 } })
        },
        fk() {
            this.$router.go(-1)
        },
        bc() {
            this.$router.go(1)
        },
        breaK() {
            localStorage.removeItem('user')
            localStorage.removeItem('loggedIn')
            this.$router.push({ name: 'login' })
        },

    },

};

</script>

<style lang="less" scoped>
button {
    // color: white;
    // background-color: rgb(0, 0, 0);
    border-radius: 5px;
}

.aaa {
    width: 120px;
    height: 50px;
    font-size: 20px;
    font-weight: bold;
}
</style>